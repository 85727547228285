<template>
  <v-col v-if="!showFilterSelect && !showSortSelect">
    <v-row>
      <v-card flat :style="$vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'">
        <v-data-table
            :headers="headers"
            :items="youtubers"
            item-key="id"
            :items-per-page="options.itemsPerPage"
            :options.sync="options"
            :server-items-length="totalYoutubers"
            :loading="tableLoading"
            :footer-props="{
              itemsPerPageOptions: []
            }"
            disable-sort
            mobile-breakpoint="0"
            :hide-default-header="$vuetify.breakpoint.smAndDown"
            hide-default-footer
            style="backgroud-color: transparent">
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field ref="keywordRef" :value="keyword" @keyup.enter="changeQuery" rounded hide-details placeholder="광고 제안을 원하는 유튜버를 검색해 보세요.">
                <div class="mt-1" slot="prepend" style="width: 140px">
                  <v-select v-model="searchOption"
                            :items="[{text: '채널명', value: 'channel'}, {text: '키워드', value: 'video'}]"
                            item-text="text"
                            item-value="value"
                            prepend-icon="search" dense solo flat hide-details></v-select>
                </div>
                <v-btn slot="append" class="white-two--text" color="#BD1328" @click="changeQuery" elevation="3">
                  검색
                </v-btn>
              </v-text-field>
            </v-toolbar>
            <div style="background-color: #F2F2F2;width:100%;height:16px"></div>
            <v-toolbar flat>
              <div class="body-2 mr-4" style="color: #808080"> <!-- v-if="$vuetify.breakpoint.mdAndUp" -->
                <span class="subheading mr-4 font-weight-bold" style="color: #484848">유튜버 목록 </span>
                <span v-if="$vuetify.breakpoint.mdAndUp">검색 결과 </span>{{totalYoutubers}}명 ・ {{parseInt(totalYoutubers/options.itemsPerPage)}} Pages
              </div>
            </v-toolbar>
            <v-divider></v-divider>
            <v-expansion-panels v-model="optionPanelIdx" flat readonly>
              <v-expansion-panel>
                <v-expansion-panel-header hide-actions class="pl-3" style="display: block">
                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col>
                      <v-chip v-for="(filter, idx) in filters" :key="idx"
                              :class="filterIdx === idx ? 'white-two--text' : ''"
                              :color="(filterIdx === idx || (filter.sel && filter.sel.length > 0 && filter.sel.indexOf(0) === -1)) ? 'rouge' : '#F5F5F5'"
                              @click="filterExpand(idx)"
                              :disabled="filter.options.length < 2"
                              :outlined="(filter.sel && filter.sel.length > 0 && filter.sel.indexOf(0) === -1) && filterIdx !== idx"
                              style="color:#484848;font-size:13px">
                        {{ (filter.sel.length > 0 && filter.sel.indexOf(0) === -1) ? filter.name + ' ' + filter.sel.length + '개' : filter.name }}
                        <v-icon small>{{filter.icon}}</v-icon>
                      </v-chip>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px" @click="filterExpand(-1)">
                            {{options.itemsPerPage}}명씩 보기
                            <v-icon small>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in ['20', '50', '100']" :key="index" @click="options.itemsPerPage = item">
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px" @click="filterExpand(-1)">
                            {{orderBy[orderIdx].name}}
                            <v-icon small>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in orderBy" :key="index" @click="orderIdx=index">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-chip @click="favorite=!favorite; filterExpand(-1)"
                              :class="favorite ? 'white-two--text' : ''"
                              :color="favorite ? 'rouge' : '#F5F5F5'" style="color:#484848;font-size:13px">
                        즐겨찾기
                      </v-chip>
                  </v-col>
                  <v-col cols="1">
                    <v-btn color="rouge" class="ma-0 white-two--text" tile flat @click="searchButtonPressed">
                        필터 적용
                    </v-btn>
                  </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-chip @click="filterExpand(-2)">
                      필터
                      <v-icon small>mdi-tune</v-icon>
                    </v-chip>
                    <v-chip @click="filterExpand(-1)">
                      정렬
                      <v-icon small>mdi-sort-variant</v-icon>
                    </v-chip>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-chip-group v-model="filters[filterIdx].sel" column multiple active-class="active white--text">
                      <v-chip v-for="(option, index) in filters[filterIdx].options" :key="index"
                              :outlined = "filters[filterIdx].sel.indexOf(index) === -1"
                              :color="filters[filterIdx].sel.indexOf(index) > -1 ? 'rouge' : ''"
                              style="color:#484848;font-size:13px; pointer-events: auto">
                        {{ option.text }}
                      </v-chip>
                    </v-chip-group>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>
          </template>
          <template v-slot:header.check="{}">
            <v-icon dense class="mb-1" :color="youtuberColor()"
                    @click="addToCampaign()">mdi-check-circle</v-icon>
          </template>
<!--          <template v-slot:header.channel="{header}">-->
<!--            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>-->
<!--          </template>-->
          <template v-slot:header.title="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.subscriberCount="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.avgViewPerSubscriber12WeeksGrade="{header}">
            <span style="font-size:10px;font-weight:normal">
              {{header.text}}
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" color="#4770BD" style="margin-bottom:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                최근 3개월간 등록한 영상의 구독자 대비 조회수 성과입니다. 최근 3개월간 등록한 영상이 없는 경우 값이 보이지 않습니다.
              </v-tooltip>
            </span>
          </template>
          <template v-slot:header.video="{header}">
            <span style="font-size:10px;font-weight:normal">
              {{header.text}}
            </span>
          </template>
          <template v-slot:header.channelPrice="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.acceptAvg="{header}">
            <span style="font-size:10px;font-weight:normal">
              {{header.text}}
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" color="#4770BD" style="margin-bottom:2px" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                광고를 제안했을 때 수락하는 금액의 평균 값입니다. 평균 수락 금액 이상으로 제안하길 권장드립니다.
              </v-tooltip>
            </span>
          </template>
          <template v-slot:header.count="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.rating="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.favorite="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
<!--          <template v-slot:header.data-table-expand="{header}">-->
<!--            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>-->
<!--          </template>-->
          <template v-slot:item.check="{item}">
            <v-row>
              <v-icon :dense="$vuetify.breakpoint.mdAndUp" class="mb-1" :color="youtuberColor(item)"
                      :disabled="item.status > 0 || !item.email"
                      @click="addToCampaign(item)">mdi-check-circle</v-icon>
              <!--        <v-simple-checkbox v-model="selected[item.index]" :disabled="item.status > 0"></v-simple-checkbox>-->
            </v-row>
          </template>
<!--          <template v-slot:item.channel="props">-->
<!--            <naverIcon v-if="props.item.channel === 'naver'"/>-->
<!--            <instaIcon v-if="props.item.channel === 'insta'"/>-->
<!--            <youtubeIcon v-if="props.item.channel === 'youtube'"/>-->
<!--          </template>-->
          <template v-slot:item.title="props">
            <v-row @click="youtuberDetailDlg(props.item)" class="py-2 pt-3 row-pointer">
              <v-col cols="2">
                <div style="display: block; position: relative">
                  <v-avatar size="40" style="position: absolute">
                    <img v-if="props.item.thumbnail" :src="props.item.thumbnail"/>
                    <img v-else src="@/assets/img/matching-placeholder.jpg">
                  </v-avatar>
                  <v-icon v-if="checkProposition(null, props.item)" size="18" color="rouge" style="margin-left: 22px; margin-top: 22px">mdi-check-circle</v-icon>
                </div>
              </v-col>
              <v-col cols="9" class="ml-2">
                <v-row style="color:#4770BD; padding-bottom:4px; font-size:13px;">
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small color="#15C072" class="mr-1" v-bind="attrs" v-on="on" v-if="props.item.innaver">mdi-at</v-icon>
                    </template>
                    네이버 인플루언서
                  </v-tooltip>
                  {{props.item.title}}
                  <v-icon small color="#4770BD" class="ml-1 mb-1" style="display:inline-block">mdi-text-box-search-outline</v-icon>
                  <v-icon v-if="props.item.mcn" small color="#4770BD" class="ml-1 mb-1" style="display:inline-block">mdi-briefcase-variant</v-icon>
                </v-row>
                <v-row style="min-height: 20px">
                  <v-chip
                    outlined
                    x-small
                    color="#34CD89"
                    class="ma-0 mr-1 mb-1"
                    v-for="item in props.item.topCategory"
                    :key="`${item + 2 + 'uuid'}`"
                  >
                    {{
                      props.item.categories[item]
                        ? `${item}${Math.ceil(props.item.categories[item])}%`
                        : `${item}`
                    }}
                  </v-chip>
                </v-row>
                <v-row class="hidden-md-and-up">
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up pb-2" @click="youtuberDetailDlg(props.item)">
              <v-col>
                <v-row style="color:#808080;font-size:10px">
<!--                  {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['youtube']}}-->
                </v-row>
                <v-row v-if="props.item.subscriberCount === -1" class="caption" style="color:#808080">
                  비공개
                </v-row>
                <v-row v-else class="caption" style="color:#808080">
                  {{parseInt(props.item.subscriberCount | numFormat)}}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  예상 광고 단가
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{parseInt(props.item.channelPrice) | numFormat}}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  평균 수락 금액
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{parseInt(props.item.acceptAvg) | numFormat}}
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up">
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  수락/거절 내역
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{ ((props.item.count || {}).accept || 0) + '회 / ' + ((props.item.count || {}).refuse || 0) + '회' }}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  수락률/평점
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{(props.item.acceptRate || '-') + '%'}}/<span style="color:#F0CB30">{{'★' + (props.item.rating || '-')}}</span>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-slot:item.subscriberCount="{item}">
            <v-row v-if="item.subscriberCount === -1" class="caption" style="color:#808080">
              비공개
            </v-row>
            <v-row v-else class="caption" style="color:#808080">
              {{parseInt(item.subscriberCount) | numFormat}}
            </v-row>
            <v-row style="color:#808080;font-size:10px">
<!--              {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['youtube']}}-->
            </v-row>
          </template>
          <template v-slot:item.avgViewPerSubscriber12WeeksGrade="{item}">
            <v-row class="caption" :style="'color:' + viewPerSubscriberMap[item.avgViewPerSubscriber12WeeksGrade].color">
              <v-icon :color="viewPerSubscriberMap[item.avgViewPerSubscriber12WeeksGrade].color" small>{{viewPerSubscriberMap[item.avgViewPerSubscriber12WeeksGrade].icon}}</v-icon>
              {{item.avgViewPerSubscriber12WeeksGrade}}
            </v-row>
          </template>
          <template v-slot:item.video="{item}">
            <div clas="caption" v-if="item.commercialVideos.length > 0">
              <v-menu v-model="item.previewMenu" :nudge-width="200" offset-y>
                <template v-slot:activator="scope">
                  <div target="_blank" style="color:#4770BD; font-size:13px" v-bind="scope.attrs" v-on="scope.on">
                    미리보기
                    <v-icon small color="#4770BD" class="mb-1">mdi-chevron-down</v-icon>
                  </div>
                </template>
                <v-card style="width:536px;background-color: #FFFFFF" class="pa-4">
                  <div class="caption mb-2">최근 광고 영상</div>
                  <v-sheet class="mx-auto" v-dragscroll style="height:228px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                    <a :href="'https://www.youtube.com/watch?v=' + item.commercialVideos[index].youtube_id" target="_blank" v-for="(video, index) in item.commercialVideos" :key="index">
                      <div class="mx-1" style="maxWidth:120px">
                        <img v-if="video.thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')" width="120px" height="108px" :src="video.thumbnail || ('https://i.ytimg.com/vi/' + video.youtube_id + '/mqdefault.jpg')" :alt="video.title"/>
                        <img v-else src="@/assets/img/matching-placeholder.jpg" style="width:128px;height:108px">
                        <div>
                          <v-chip outlined x-small color="#34CD89"
                                  class="ma-0 mr-1">
                            {{item.commercialVideos[index].category}}
                          </v-chip>
                        </div>
                        <p v-html="video.title" class="caption" style="width:120px;color:#484848;white-space:normal;"></p>
                      </div>
                    </a>
                  </v-sheet>
                  <div v-if="(item.reviews || []).length > 0" class="caption my-2">슈퍼차트 캠페인 진행 사례</div>
                  <v-sheet v-if="(item.reviews || []).length > 0" class="mx-auto" v-dragscroll style="height:228px; display: flex; overflow: scroll;overflow-x:auto; white-space: nowrap;">
                    <a :href="item.reviews[index].url.replace('m.', '')" target="_blank" v-for="(review, index) in item.reviews" :key="index">
                      <div class="mx-1" style="maxWidth:120px">
                        <img v-if="review.image" width="120px" height="108px" :src="review.image" :alt="review.title"/>
                        <img v-else src="@/assets/img/matching-placeholder.jpg">
                        <p v-html="review.title" class="caption" style="width:120px;color:#484848;white-space:normal;"></p>
                      </div>
                    </a>
                  </v-sheet>
                </v-card>
              </v-menu>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.channelPrice="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{parseInt(item.channelPrice) | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.acceptAvg="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{item.acceptAvg | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.count="{item}">
            <v-row class="caption" style="color:#808080">
              <v-col>
                <v-row>{{ ((item.count || {}).accept || (item.count || {}).refuse) ? ((item.count || {}).accept || 0) + '회 / ' + ((item.count || {}).refuse || 0) + '회' : '-' }}</v-row>
                <v-row class="caption" v-if="item.email" style="color: #34CD89">제안 가능</v-row>
                <v-row class="caption" v-else>제안 불가</v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.rating="{item}">
            <v-row class="caption" style="color:#808080">
              {{(item.acceptRate || '-') + '%'}}/<span style="color:#F0CB30">{{'★' + (item.rating || '-')}}</span>
            </v-row>
          </template>
          <template v-slot:item.favorite="{item}">
            <v-icon :color="checkFavorite(item) ? 'pink' : ''" @click="setFavorite(item)">{{checkFavorite(item) ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="2" class="hidden-sm-and-down">
            </td>
            <td :colspan="7">
              <v-row align="center">
                <v-col cols="2" style="font-size:10px;color:#808080">
                  제안 금액
                  <v-tooltip bottom max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                    </template>
                    예상 광고단가의 80-200% 금액으로 제안하실 수 있습니다.
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model.number="item.price" type="number"
                                @input="item.price = Math.round(Math.abs($event))"
                                @keypress="isNumber($event)"
                                outlined dense hide-details style="font-size:13px">
<!--                                :disabled="!editable[item.title]">-->
<!--                    <v-icon slot="prepend-inner"-->
<!--                            @click="editable[item.title] = !editable[item.title]" class="edit-button">-->
<!--                      edit-->
<!--                    </v-icon>-->
                  </v-text-field>
                </v-col>
                <v-spacer class="hidden-sm-and-down"></v-spacer>
                <v-col cols="3">
                  <v-menu v-model="item.campaignAddMenu" :close-on-content-click="false" :nudge-width="200" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="rouge" class="ma-0 ml-4 white-two--text" tile flat v-bind="attrs" v-on="on" style="font-size:13px" @click="checkPrice(item)">
                        <v-icon small class="mr-2">add</v-icon>
                        제안 목록에 추가
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader style="color:#484848;font-size:13px">다음 캠페인에 추가</v-subheader>
                        <v-list-item v-if="youtubeCampaigns.length === 0">
                          <v-list-item-title style="font-size:14px">
                            <router-link to="/regcam/" style="color:#484848"><v-icon>mdi-plus-circle-outline</v-icon> 새 캠페인 추가</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="(campaign, index) in youtubeCampaigns" @click="updateToProposition(campaign, item)" :key="index">
                          <v-list-item-title style="font-size:14px" :style="checkProposition(campaign, item) === 2 ? 'color:#c2c2c2' : 'color:#484848'">{{ campaign.name }}
                            <v-chip outlined small color="#757575" v-if="checkProposition(campaign, item) === 2">제안 중</v-chip>
                            <v-chip outlined small color="#BD1328" v-else-if="checkProposition(campaign, item)">삭제</v-chip>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
              v-model="options.page"
              :length="Math.floor(totalYoutubers / options.itemsPerPage + 1)"
              :total-visible="10"
          ></v-pagination>
        </div>
      </v-card>
    </v-row>
    <v-expand-transition>
      <v-card v-if="bottomNav" class="transition-fast-in-fast-out overlay" style="height: 160px;z-index:1">
        <v-divider color="#BD1328"></v-divider>
        <v-divider color="#BD1328"></v-divider>
        <v-row class="pa-4 pb-0" style="background-color: white">
          <v-col md="6" style="font-size:13px;color: #484848;">
            <v-row>
              제안 캠페인
            </v-row>
            <v-row class="mt-2" v-if="youtubeCampaigns.length > 0">
              <v-select :items="youtubeCampaigns"
                        item-text="name"
                        item-value="id"
                        :value="((youtubeCampaigns || [])[selectedCampaign] || {}).id"
                        height="40px"
                        hide-details outlined solo flat single-line
                        @change="changeCampaign">
              </v-select>
            </v-row>
            <v-row v-else>
              <v-btn tile outlined color="rouge" to="/regcam/">+ 새 캠페인 추가</v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions class="px-4 py-3">
          <span><b style="font-size: 14px; color: #BD1328">{{_.compact(selected).length}}명</b>의 인원을 캠페인 제안 목록에 추가합니다.</span>
          <v-spacer></v-spacer>
          <v-btn flat tile color="rouge white--text" @click="updateAllToProposition()" :disabled="_.compact(selected).length === 0" width="160" height="40">
            제안 목록에 추가
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
    <v-row justify="center" align="center">
      <v-dialog v-model="showLocalFilterDialog" content-class="white-two" max-width="500px">
        <v-card class="pa-4">
          <v-card-title class="pa-2">
            <div>
              <v-icon color="default" @click="showLocalFilterDialog=false">close</v-icon>
              <br>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="font-weight-bold ml-6">
              활동 지역 선택
            </v-row>
            <v-row class="ml-6">
              <v-chip @click="filters[LOCALIDX].sel=[]">
                지역 전체
              </v-chip>
            </v-row>
            <v-expansion-panels flat>
              <v-expansion-panel v-for="(subRegion, region) in locals" :key="region" style="background-color: white">
                <v-expansion-panel-header>
                  {{region}}{{'(' + (_.intersection(_.concat(_.keys(locals[region]), [region]), filters[LOCALIDX].sel).length || '') + ')'}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip @click="setLocalSels(region, null)"
                          :class="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'white-two--text' : ''"
                          :color="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'rouge' : '#F5F5F5'">
                    전체
                  </v-chip>
                  <v-chip v-for="(value, key) in subRegion" :key="key"
                          @click="setLocalSels(region, key)"
                          :class="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'white-two--text' : ''"
                          :color="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"
                          style="color:#484848;font-size:13px">
                    {{value}}
                  </v-chip>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
<!--            <div v-for="(subRegion, region) in locals" :key="region">-->
<!--              <v-row class="mt-4">-->
<!--                {{region}}-->
<!--              </v-row>-->
<!--              <v-chip v-for="(value, key) in locals[region]" :key="key"-->
<!--                      @click="localSels.indexOf(key) > -1 ? (localSels = localSels.filter((sel) => sel !== key)) : localSels.push(key)"-->
<!--                      :class="localSels.indexOf(key) > -1 ? 'white-two&#45;&#45;text' : ''"-->
<!--                      :color="localSels.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"-->
<!--                      style="color:#484848;font-size:13px">-->
<!--                {{value}}-->
<!--              </v-chip>-->
<!--            </div>-->
          </v-card-text>
<!--          <v-card-actions>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn tile outlined color="rouge" @click="showLocalFilterDialog=false">취소</v-btn>-->
<!--            <v-btn class="rouge white-two&#45;&#45;text" tile flat @click="showLocalFilterDialog=false">선택</v-btn>-->
<!--          </v-card-actions>-->
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showCompleteDialog" content-class="white-two" max-width="256px">
        <v-card class="pa-4">
          <v-card-subtitle style="font-size:13px;padding:0">
            {{completeDialogTitle}}
          </v-card-subtitle>
          <v-card-text>
            <div class="body-1" style="color:#484848;">{{completeDialogTxt1}}</div>
            <div class="" style="font-size:13px;color:#757575;">{{completeDialogTxt2}}</div>
          </v-card-text>
          <v-card-actions style="padding: 16px 0 0 0">
            <v-spacer></v-spacer>
            <v-btn color="rouge" tile flat outlined @click="$router.push({ name: 'PropositionYoutube', params: {initCampaign: selectedCampaign}})">목록으로</v-btn>
            <v-btn class="rouge white-two--text" tile flat @click="showCompleteDialog=false">확인</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- youtuber detail -->
    <v-row justify="center">
      <YoutuberDetail v-model="openYoutuberDetail"
                        :openYoutuber="openYoutuber"
                        :checkProposition="checkProposition"
                        :updateToProposition="updateToProposition"
                        :checkFavorite="checkFavorite"
                        :setFavorite="setFavorite"
                        :statistics="statistics">
      </YoutuberDetail>
    </v-row>
  </v-col>
  <v-col v-else-if="showFilterSelect" style="margin-bottom: -24px">
    <v-toolbar flat>
      <div @click="showFilterSelect=false">
        < 필터 선택
      </div>
    </v-toolbar>
    <v-row v-for="(filter, idx) in filters" :key="idx">
      <v-col v-if="idx !== LOCALIDX" style="background-color: white" class="pa-4 mt-2">
        <v-row>
          {{filter.name}}
        </v-row>
        <v-row>
          <v-chip-group v-model="filter.sel" column multiple active-class="active white--text">
            <v-chip v-for="(option, index) in filter.options" :key="index"
                    @click="expand=false"
                    :outlined = "filter.sel.indexOf(index) === -1"
                    :color="filter.sel.indexOf(index) > -1 ? 'rouge' : ''"
                    style="color:#484848;font-size:13px; pointer-events: auto">
              {{ option.text }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row style="background-color: white">
          활동 지역
        </v-row>
        <v-row style="background-color: white">
          <v-chip @click="filters[LOCALIDX].sel=[]">
            지역 전체
          </v-chip>
        </v-row>
        <v-expansion-panels flat>
          <v-expansion-panel v-for="(subRegion, region) in locals" :key="region" style="background-color: white">
            <v-expansion-panel-header>
              {{region}}{{'(' + (_.intersection(_.concat(_.keys(locals[region]), [region]), filters[LOCALIDX].sel).length || '') + ')'}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-chip @click="setLocalSels(region)"
                      :class="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'white-two--text' : ''"
                      :color="filters[LOCALIDX].sel.indexOf(region) > -1 ? 'rouge' : '#F5F5F5'">
                전체
              </v-chip>
              <v-chip v-for="(value, key) in locals[region]" :key="key"
                      @click="setLocalSels(region, key)"
                      :class="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'white-two--text' : ''"
                      :color="filters[LOCALIDX].sel.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"
                      style="color:#484848;font-size:13px">
                {{value}}
              </v-chip>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="py-4" style="background-color: white">
      <v-col cols="6">
        <v-btn color="#757575" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="() => {
          filters.forEach((filter) => {
            filter.sel = 0;
          });
        }">
          초기화
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="rouge" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="showFilterSelect=false, searchButtonPressed()">
          선택 적용
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else style="margin-bottom: -24px">
    <v-toolbar flat>
      <div @click="showSortSelect=false">
        < 정렬 기준
      </div>
    </v-toolbar>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          즐겨찾기 항목만 보기
        </v-row>
        <v-row>
          <v-chip @click="favorite=!favorite"
                  :class="favorite ? 'white-two--text' : ''"
                  :color="favorite ? 'rouge' : '#F5F5F5'" style="color:#484848;font-size:13px">
            지금 적용
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          정렬 순서
        </v-row>
        <v-row>
          <v-chip v-for="(item, index) in orderBy" :key="index"
                  @click="orderIdx=index"
                  :class="orderIdx === index ? 'white-two--text' : ''"
                  :outlined = "orderIdx !== index"
                  :color="orderIdx === index ? 'rouge' : ''"
                  style="color:#484848;font-size:13px">
            {{item.name}}
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="background-color: white" class="pa-4 mt-2">
        <v-row>
          한 페이지에 보이는 항목 수
        </v-row>
        <v-row>
          <v-chip v-for="(item, index) in ['20', '50', '100']" :key="index"
                  @click="options.itemsPerPage=item"
                  :class="options.itemsPerPage === item ? 'white-two--text' : ''"
                  :outlined = "options.itemsPerPage !== item"
                  :color="options.itemsPerPage === item ? 'rouge' : ''"
                  style="color:#484848;font-size:13px">
            {{item}}
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-4" style="background-color: white">
      <v-col cols="6">
        <v-btn color="#757575" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="options.itemsPerPage=20;orderIdx=0">
          초기화
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="rouge" class="ma-0 white-two--text" width="100%" tile flat style="font-size:13px" @click="showSortSelect=false, searchButtonPressed">
          선택 적용
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import campaignMixin from '@/components/shared/campaignMixin'
  import YoutuberDetail from '@/components/campaign/dialog/YoutuberDetail'

  export default {
    name: "SearchYoutube",
    mixins: [userMixin, campaignMixin],
    components: {
      YoutuberDetail
    },
    data() {
      return {
        expanded: [],
        editable: {},
        orderBy: [{name: '구독자 많은 순', sql: 'orderBy=subscriberCount&direction=desc'},
          {name: '구독자 적은 순', sql: 'orderBy=subscriberCount&direction=asc'}],
        LOCALIDX: 4,
        headers_desktop: [
          { text: '', value: 'check'},
          { text: '유튜버 정보', value: 'title', width: '280px' },
          { text: '광고 사례', value: 'video' },
          { text: '구독자 수', value: 'subscriberCount' },
          { text: '최근 3개월 성과', value: 'avgViewPerSubscriber12WeeksGrade' },
          { text: '예상 광고 단가', value: 'channelPrice' },
          { text: '평균 수락 금액', value: 'acceptAvg' },
          { text: '제안/수락 내역', value: 'count' },
          { text: '수락률/평점', value: 'rating' },
          { text: '즐겨찾기', value: 'favorite' },
        ],
        headers_mobile: [
          { text: '', value: 'check', width: '40px'},
          { text: '인플루언서 정보', value: 'title' },
          { text: '제안하기', value: 'data-table-expand', width: '78px' }
        ],
        filters: [
          {name: '카테고리', icon: 'expand_more', options: [
              {text: '전체', query: ''},
              {text: '게임', query: 'topCategory[]=게임&categories[]=게임'},
              {text: 'BJ/엔터테인먼트', query: 'topCategory[]=BJ/엔터테인먼트&categories[]=BJ/엔터테인먼트'},
              {text: '경제/금융', query: 'topCategory[]=경제/금융&categories[]=경제/금융'},
              {text: '교육/강의', query: 'topCategory[]=교육/강의&categories[]=게임'},
              {text: '동물/펫', query: 'topCategory[]=동물/펫&categories[]=게임'},
              {text: 'Vlog/일상', query: 'topCategory[]=Vlog/일상&categories[]=게임'},
              {text: '뷰티/패션', query: 'topCategory[]=뷰티/패션&categories[]=게임'},
              {text: '스포츠/건강', query: 'topCategory[]=스포츠/건강&categories[]=게임'},
              {text: '여행/아웃도어', query: 'topCategory[]=여행/아웃도어&categories[]=게임'},
              {text: '영화/애니', query: 'topCategory[]=영화/애니&categories[]=게임'},
              {text: '음악/댄스', query: 'topCategory[]=음악/댄스&categories[]=게임'},
              {text: '정치/이슈', query: 'topCategory[]=정치/이슈&categories[]=게임'},
              {text: '종교/운세', query: 'topCategory[]=종교/운세&categories[]=게임'},
              {text: '차/배/바이크', query: 'topCategory[]=차/배/바이크&categories[]=게임'},
              {text: '그림/만들기', query: 'topCategory[]=그림/만들기&categories[]=게임'},
              {text: '키즈', query: 'topCategory[]=키즈&categories[]=게임'},
              {text: '음식/요리', query: 'topCategory[]=음식/요리&categories[]=게임'},
              {text: 'IT/기술', query: 'topCategory[]=IT/기술&categories[]=게임'},
              {text: '기타', query: 'topCategory[]=기타&categories[]=게임'}
            ], sel: [0]},
          {name: '시청자층', icon: 'mdi-tune', options: [
              {text: '전체', query: ''},
              {text: '10대 남성', query: 'viewers[]=10대 남성'},
              {text: '10대 여성', query: 'viewers[]=10대 여성'},
              {text: '20~30대 남성', query: 'viewers[]=20대 남성&viewers[]=30대 남성'},
              {text: '20~30대 여성', query: 'viewers[]=20대 여성&viewers[]=30대 여성'},
              {text: '40~50대 남성', query: 'viewers[]=40대 남성&viewers[]=50대 남성'},
              {text: '40~50대 여성', query: 'viewers[]=40대 여성&viewers[]=50대 여성'},
              {text: '60대 남성', query: 'viewers[]=60대 이상 남성'},
              {text: '60대 여성', query: 'viewers[]=60대 이상 여성'},
            ], sel: [0]},
          {name: '제안 가능 여부', icon: 'expand_more', options: [
              {text: '전체', query: ''},
              {text: '제안 가능만 보기', sql: 'yc.email is not null'}
            ], sel: [0]},
          {name: 'MCN 여부', icon: 'expand_more', options: [
              {text: '전체', query: ''},
              {text: '소속 MCN 있음', sql: 'yc.mcn is not null'},
              {text: '소속 MCN 없음', sql: 'yc.mcn is null'}
            ], sel: [0]},
          {name: '활동 지역', icon: 'expand_more', options: [
            ], sel: [0]},
          {name: '구독자수', icon: 'expand_more', options: [
              {text: '전체', sql: 'yc.subscriberCount >= 100 AND yc.subscriberCount < 3000000'},
              {text: '0-1,000', sql: 'yc.subscriberCount >= 100 AND yc.subscriberCount < 1000'},
              {text: '1,000-10,000', sql: 'yc.subscriberCount >= 1000 AND yc.subscriberCount < 10000'},
              {text: '10,000-30,000', sql: 'yc.subscriberCount >= 10000 AND yc.subscriberCount < 30000'},
              {text: '30,000-100,000', sql: 'yc.subscriberCount >= 30000 AND yc.subscriberCount < 100000'},
              {text: '100,000-300,000', sql: 'yc.subscriberCount >= 100000 AND yc.subscriberCount < 300000'},
              {text: '300,000 이상', sql: 'yc.subscriberCount >= 300000 AND yc.subscriberCount < 3000000'}
              // {text: '직접 입력', sql: ''}
            ], sel: [0]},
          {name: '최근 3개월 성과', icon: 'expand_more', options: [
              {text: '전체', query: ''},
              {text: '매우 낮음', sql: 'yc.avgViewPerSubscriber12WeeksGrade="매우 낮음"'},
              {text: '낮음', sql: 'yc.avgViewPerSubscriber12WeeksGrade="낮음"'},
              {text: '보통', sql: 'yc.avgViewPerSubscriber12WeeksGrade="보통"'},
              {text: '높음', sql: 'yc.avgViewPerSubscriber12WeeksGrade="높음"'},
              {text: '매우 높음', sql: 'yc.avgViewPerSubscriber12WeeksGrade="매우 높음"'}
            ], sel: [0]},
          {name: '단가', icon: 'mdi-tune', options: [
              {text: '전체', sql: ''},
              {text: '20만원 미만', sql: 'yc.channelPrice >= 0 AND yc.channelPrice < 200000'},
              {text: '20만-50만원', sql: 'yc.channelPrice >= 200000 AND yc.channelPrice < 500000'},
              {text: '50만-100만원', sql: 'yc.channelPrice >= 500000 AND yc.channelPrice < 1000000'},
              {text: '100만-300만원', sql: 'yc.channelPrice >= 1000000 AND yc.channelPrice < 3000000'},
              {text: '300만-1,000만원', sql: 'yc.channelPrice >= 3000000 AND yc.channelPrice < 10000000'},
              {text: '1,000만원이상', sql: 'yc.channelPrice >= 10000000'},
            ], sel: [0]},
          {name: '숏폼 비중', icon: 'expand_more', options: [
              {text: '전체', sql: ''},
              {text: '10%이하', sql: 'shortRate >= 0 AND shortRate < 10'},
              {text: '10%~30%', sql: 'shortRate >= 10 AND shortRate < 30'},
              {text: '30%~50%', sql: 'shortRate >= 30 AND shortRate < 50'},
              {text: '50%이상', sql: 'shortRate > 50'},
            ], sel: [0]}
        ],
        keyword: '',
        oldFilterIdx: 0,
        filterIdx: 0,
        orderIdx: 0,
        youtubers: [],
        totalYoutubers: 0,
        tableLoading: false,
        options: { page: 1, itemsPerPage: 20 },
        propositions: [],
        showCompleteDialog: false,
        completeDialogTitle: '',
        completeDialogTxt1: '',
        completeDialogTxt2: '',
        favorite: false,
        favorites: [],
        openYoutuber: {},
        openYoutuberDetail: false,
        showLocalFilterDialog: false,
        locals: {},
        keywordIdx: 0,
        showFilterSelect: false,
        showSortSelect: false,
        selected: [],
        bottomNav: true,
        selectedCampaign: 0,
        viewPerSubscriberMap: {
          '매우 낮음': {icon: 'mdi-chevron-double-down', color: '#4770BD'},
          '낮음': {icon: 'mdi-chevron-down', color: '#4770BD'},
          '보통': {icon: 'mdi-circle-small', color: '#484848'},
          '높음': {icon: 'mdi-chevron-up', color: '#BD1328'},
          '매우 높음': {icon: 'mdi-chevron-double-up', color: '#BD1328'},
          null: {}
        },
        searchOption: 'channel',
        optionPanelIdx: null,
        statistics: []
      }
    },
    computed: {
      headers() {
        return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
      },
      filters_str() {
        return JSON.stringify(this.filters)
      }
    },
    watch: {
      options: {
        handler () {
          if (this.$route.query.keyword || this.keyword) {
            this.search(false);
          }
          else {
            this.selected = [];
            this.getDataFromApi();
          }
        },
        deep: true,
      },
      filters_str(newValue_str, oldValue_str) {
        let newValue = JSON.parse(newValue_str);
        let oldValue = JSON.parse(oldValue_str);
        newValue.map((filter, idx) => {
          if (!this._.isEqual(filter.sel, oldValue[idx].sel)) {
            let index = filter.sel.indexOf(0);
            if (filter.sel.length > 1 && index > -1) {
              if (index === 0)
                this.filters[idx].sel.splice(index, 1)
              else
                this.filters[idx].sel.shift();
            }
          }
        });
        this.options.page = 1;
        // this.getDataFromApi();
      },
      // filters: {
      //   handler (newValue, oldValue) {
      //     newValue.map((filter, idx) => {
      //       if (!this._.isEqual(filter.sel, oldValue[idx].sel)) {
      //         let index = newValue.indexOf(0);
      //         if (newValue.length > 1 && index > -1) {
      //           if (index === 0)
      //             newValue.splice(index, 1)
      //           else
      //             newValue.shift();
      //         }
      //       }
      //     });
      //
      //     this.options.page = 1;
      //     this.getDataFromApi();
      //   },
      //   deep: true,
      // },
      // localSels: {
      //   handler () {
      //     this.options.page = 1;
      //     this.getDataFromApi();
      //   },
      //   deep: true
      // },
      favorite: {
        handler () {
          this.getDataFromApi();
        }
      },
      orderIdx: {
        handler () {
          if (this.$route.query.keyword || this.keyword) {
            this.search(false);
          }
          else {
            // this.getDataFromApi();
          }
        },
        deep: true,
      },
      '$route.query.keyword': {
        async handler () {
          // if ((this.$route.query.keyword || "").length > 0) {
            this.search(true);
          // }
        },
        deep: true,
      },
      searchOption() {
        if (this.keyword && this.keyword.length > 1) {
          this.options.page = 1;
          this.search(true);
        }
      }
    },
    methods: {
      filterExpand (idx) {
        if (idx === -1) {
          if (this.$vuetify.breakpoint.mdAndUp) {
            this.optionPanelIdx = 1;
            this.showSortSelect = false;
          }
          else {
            this.optionPanelIdx = 1;
            this.showSortSelect = true;
          }
        }
        else {
          if (idx >= 0 && idx === this.LOCALIDX) {
            this.optionPanelIdx = 1;
            this.showLocalFilterDialog = true;
          }
          else if (this.filterIdx === idx) {
            this.optionPanelIdx = 1 - this.optionPanelIdx;
          }
          else {
            if (this.$vuetify.breakpoint.mdAndUp) {
              this.optionPanelIdx = 0;
              this.showLocalFilterDialog = false;
            } else {
              this.optionPanelIdx = 0;
              this.showFilterSelect = true;
            }
          }
          if (idx >= 0) {
            this.filterIdx = idx;
          }
        }
      },
      checkProposition(campaign, youtuber) {
        if (campaign) {
          if (this._.find(this.propositions, (proposition) => {
            return (proposition.campaignId === campaign.id
                && proposition.taggableId === youtuber.id
                && proposition.taggableType === 'youtube'
                && proposition.status === 1);
          })) {
            return 2
          }
          else {
            return this._.find(this.propositions, (proposition) => {
              return (proposition.campaignId === campaign.id
                  && proposition.taggableId === youtuber.id
                  && proposition.taggableType === 'youtube'
                  && proposition.status < 2);
            });
          }
        }
        else {
          return (this.youtubeCampaigns || []).some((campaign) => {
            return this._.find(this.propositions, (proposition) => {
              return (proposition.campaignId === campaign.id
                && proposition.taggableId === youtuber.id
                && proposition.taggableType === 'youtube'
                && proposition.status < 2);
            });
          })
        }
      },
      async updateToProposition(campaign, youtuber) {
        if (campaign[youtuber.channel] === undefined) {
          this.$store.commit('setMsg', `채널 정보가 없어 목록에 추가할 수 없는 캠페인입니다.`);
          this.$store.commit('setShowSnackbar', true);
          return;
        }
        this.completeDialogTxt1 = campaign.name;
        let oldProposition = this.checkProposition(campaign, youtuber);
        if (!oldProposition) {
          let maxEditCount = this.$getMaxEditCount(youtuber.price);
          let proposition = {
            campaignId: campaign.id,
            taggableId: youtuber.id,
            taggableType: 'youtube',
            uid: youtuber.uid,
            point: youtuber.price,
            cname: campaign.serviceName,
            creward: campaign[youtuber.channel].reward,
            cprice: campaign[youtuber.channel].rewardPrice,
            category: campaign.category,
            status: 0,
            maxEditCount: maxEditCount,
            thumbnail: campaign.thumbnail,
            periodUse: this.$periodMap[youtuber.channel][campaign.type][campaign.category.firstName][0],
            periodFirstCopy: this.$periodMap[youtuber.channel][campaign.type][campaign.category.firstName][1],
            fees: 30
          }
          if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
            proposition.rewardFees = 13.5;
            proposition.rewardFeeAmount = campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
          }

          await this.axios.post('/campaign/proposition', proposition);
          await this.getPropositions();
          this.completeDialogTitle = '추가 완료';
          this.completeDialogTxt2 = '제안목록에 추가되었습니다.';
          this.showCompleteDialog = true;
        }
        else if (oldProposition !== 2) {
          await this.axios.delete('/campaign/proposition?id=' + oldProposition.id);
          await this.getPropositions();
          this.completeDialogTitle = '삭제 완료';
          this.completeDialogTxt2 = '제안목록에서 삭제되었습니다.';
          this.showCompleteDialog = true;
        }
      },
      async updateAllToProposition() {
        let campaign = this.youtubeCampaigns[this.selectedCampaign];
        if (campaign['youtube'] === undefined) {
          this.$store.commit('setMsg', `채널 정보가 없어 목록에 추가할 수 없는 캠페인입니다.`);
          this.$store.commit('setShowSnackbar', true);
          return;
        }
        this.completeDialogTxt1 = campaign.name;
        await Promise.all(this.selected.map(async(select, idx) => {
          if (select) {
            let youtuber = this.youtubers[idx];
            let oldProposition = this.checkProposition(campaign, youtuber);
            if (!oldProposition) {
              let maxEditCount = this.$getMaxEditCount(youtuber.price);
              let proposition = {
                campaignId: campaign.id,
                taggableId: youtuber.id,
                taggableType: 'youtube',
                uid: youtuber.uid,
                point: youtuber.price,
                cname: campaign.serviceName,
                creward: campaign[youtuber.channel].reward,
                cprice: campaign[youtuber.channel].rewardPrice,
                category: campaign.category,
                status: 0,
                maxEditCount: maxEditCount,
                thumbnail: campaign.thumbnail,
                periodUse: this.$periodMap[youtuber.channel][campaign.type][campaign.category.firstName][0],
                periodFirstCopy: this.$periodMap[youtuber.channel][campaign.type][campaign.category.firstName][1],
                fees: 30
              }
              if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
                proposition.rewardFees = 13.5;
                proposition.rewardFeeAmount = campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
              }

              return await this.axios.post('/campaign/proposition', proposition);
            }
          }
        }));
        await this.getPropositions();
        this.completeDialogTitle = '추가 완료';
        this.completeDialogTxt2 = '제안목록에 추가되었습니다.';
        this.showCompleteDialog = true;
      },
      changeQuery() {
        this.keyword = this.$refs.keywordRef.internalValue;
        this.keywordIdx = 0;
        if (!/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(this.keyword) && this.keyword.length === 1) {
          return;
        }
        if (this.keyword.length > 0) {
          this.$router.push({path: 'searchyoutube', query: {keyword: this.keyword}});
        }
        else {
          this.$router.push({path: 'searchyoutube'});
        }
      },
      async search(isFirst) {
        const { page, itemsPerPage} = this.options
        let pageQuery = '&page=' + (isFirst ? 1 : page) + '&limit=' + itemsPerPage;
        try {
          let filterQuery = '&query=yc.subscriberCount >= 100 AND yc.subscriberCount < 3000000';
          let youtubers = await this.axios.get('/youtuber?search=' + (this.$route.query.keyword || this.keyword) +
              '&searchOption=' + this.searchOption + '&' + this.orderBy[this.orderIdx].sql + filterQuery + pageQuery);
          this.totalYoutubers = youtubers.data.total;
          this.youtubers = youtubers.data.youtubers;

          this.youtubers = this.youtubers.map((youtuber) => {
            // let mainCategories = {};
            // this._.toPairs(youtuber.categories).map((category) => {
            //   this.categories.forEach((maincategory) => {
            //     if (maincategory.sub.indexOf(category[0]) > -1) {
            //       mainCategories[maincategory.name] = mainCategories[maincategory.name] || 0;
            //       mainCategories[maincategory.name] += category[1];
            //     }
            //   });
            // });
            // youtuber.mainCategories = mainCategories;
            youtuber.price = youtuber.channelPrice;
            return youtuber;
          });
        }
        catch (e) {
          console.log(e);
        }
      },
      async setFavorite(youtuber) {
        if (this.checkFavorite(youtuber)) {
          await this.axios.delete('/company/user/favorite?companyId=' + this.user.id +
            '&taggableId=' + youtuber.id + '&taggableType=youtube');
        }
        else {
          await this.axios.post('/company/user/favorite', {companyId: this.user.id, taggableId: youtuber.id, taggableType: 'youtube'});
        }

        let favorites = await this.axios.get('/company/user/favorite?companyId=' + this.user.id + '&taggableType=youtube&inc=1');
        this.favorites = favorites.data.favorites;
      },
      checkFavorite(youtuber) {
        return this._.find(this.favorites, (favorite) => {
          return (favorite.taggableId === youtuber.id && favorite.taggableType === youtuber.channel)
        });
      },
      async getDataFromApi() {
        // this.$router.push({ path: 'searchyoutube'});
        this.tableLoading = true;
        const { /*sortBy, sortDesc, */page, itemsPerPage} = this.options
        let pageQuery = '&page=' + page + '&limit=' + itemsPerPage;
        let query = '';
        let sql = 'query=';
        this.filters.forEach((filter, idx) => {
          if (idx === this.LOCALIDX && this.filters[this.LOCALIDX].sel.length > 0) {
            this.filters[this.LOCALIDX].sel.forEach((sel) => {
              if (this.locals[sel]) {
                Object.keys(this.locals[sel]).map((key) => {
                  query += '&local[]=' + key;
                });
              }
              else if(sel != 0)
              {
                query += '&local[]=' + sel;
              }
            });
          }
          else {
            if (filter.sel > 0 && filter.options[filter.sel].query) {
              query += '&' + filter.options[filter.sel].query;
            }

            if (filter.sel.length > 0) {
              let selSql = '';
              filter.sel.forEach((sel) => {
                if (filter.options[sel].sql && filter.options[sel].sql.length > 0) {
                  selSql += (selSql.length > 7 ? ' OR ' : '') + encodeURIComponent(filter.options[sel].sql);
                }
              });
              if (selSql.length > 0) {
                sql += (sql.length > 7 ? ' AND ' : '') + '(' + selSql + ')';
              }
            }
          }
        });

        if (this.keyword) {
          query += ('&search=' + this.keyword);
        }

        if (this.favorite) {
          query += ('&favorite=' + true + '&companyId=' + this.user.id);
        }

        let youtubers = await this.axios.get('/youtuber?' + this.orderBy[this.orderIdx].sql +
          pageQuery + query + (sql.length > 7 ? ('&' + sql) : ''));
        this.totalYoutubers = youtubers.data.total;
        this.youtubers = youtubers.data.youtubers;
        this.tableLoading = false;

        this.youtubers = this.youtubers.map((youtuber) => {
          // let mainCategories = {};
          // this._.toPairs(youtuber.categories).map((category) => {
          //   this.categories.forEach((maincategory) => {
          //     if (maincategory.sub.indexOf(category[0]) > -1) {
          //       mainCategories[maincategory.name] = mainCategories[maincategory.name] || 0;
          //       mainCategories[maincategory.name] += category[1];
          //     }
          //   });
          // });
          // youtuber.mainCategories = mainCategories;
          youtuber.price = youtuber.channelPrice;

          let propositions = this.propositions.filter((proposition) => (proposition.taggableId == youtuber.id));
          youtuber.count = this._.countBy(propositions, (proposition) => {
            if (proposition.status === 2 || proposition.status >= 10) {
              return 'accept';
            }
            else if (proposition.status === 3 || proposition.status === 4) {
              return 'refuse';
            }
            else if (proposition.status === 1) {
              return 'wait';
            }
            else {
              return 'list';
            }
          });
          return youtuber;
        });
      },
      async youtuberDetailDlg(item, event) {
        console.log(event)
        let preview = this.youtubers.some((youtuber) => youtuber.previewMenu);
        if (preview)
          return;
        this.openYoutuberDetail = true;
        this.openYoutuber = item;
      },
      checkPrice(youtuber) {
        if ((youtuber.price || 0) < Math.round(youtuber.channelPrice * this.$minYoutubeProposeRate)) {
          this.$store.commit('setMsg', `해당 회원의 최소 제안가격은 ${Math.round(youtuber.channelPrice * this.$minYoutubeProposeRate)}원 입니다`);
          youtuber.price = Math.round(youtuber.channelPrice * this.$minYoutubeProposeRate);
          this.$store.commit('setShowSnackbar', true);
        }
        else if(youtuber.price > (youtuber.channelPrice * this.$maxProposeRate)) {
          this.$store.commit('setMsg', `해당 회원의 최대 제안가격은 ${youtuber.channelPrice * this.$maxProposeRate}원 입니다`);
          youtuber.price = youtuber.channelPrice * this.$maxProposeRate;
          this.$store.commit('setShowSnackbar', true);
        }
      },
      isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode < 48 || charCode > 57) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      youtuberColor(item) {
        if (item) {
          if (item.email) {
            let index = this.youtubers.indexOf(item);
            if (index > -1) {
              return ((this.selected[index]) ? 'rouge' : 'default-light');
            } else {
              return '';
            }
          }
          else {
            return '#C2C2C2'
          }
        } else {
          return ((this.selected.filter(Boolean).length > 0) ? 'rouge' : 'default-light');
        }
      },
      addToCampaign(item) {
        if (item) {
          let index = this.youtubers.indexOf(item);
          if (index > -1) {
            this.selected[index] = !this.selected[index];
            this.$forceUpdate();
          }
        }
        else {
          let check = this.youtubers.every((youtuber, index) => {
            return this.selected[index];
          })
          this.youtubers.map((youtuber, index) => {
            this.selected[index] = !check;
          });
          this.$forceUpdate();
        }
        // this.bottomNav = this.selected.some((select) => select);
      },
      changeCampaign(id) {
        this.youtubeCampaigns.some((campaign, idx) => {
          if (campaign.id === id) {
            this.selectedCampaign = idx;
            return true;
          }
          return false
        });
      },
      async getPropositions() {
        let filterCampaign = '';
        if (this.youtubeCampaigns.length > 0) {
          this.youtubeCampaigns.map((campaign, idx) => {
            if (idx) {
              filterCampaign += ('&campaignId[]=' + campaign.id);
            } else {
              filterCampaign += ('?campaignId[]=' + campaign.id);
            }
          });
        }
        let propositions = await this.axios.get('/campaign/proposition' + filterCampaign);
        this.propositions = propositions.data.propositions;
      },
      setLocalSels(region, key) {
        if (key) {
          if (this.filters[this.LOCALIDX].sel.indexOf(region) > -1) {
            this.filters[this.LOCALIDX].sel = this.filters[this.LOCALIDX].sel.filter((sel) => sel !== region)
          }
          if(this.filters[this.LOCALIDX].sel.indexOf(key) > -1) {
            this.filters[this.LOCALIDX].sel = this.filters[this.LOCALIDX].sel.filter((sel) => sel !== key)
          } else {
            this.filters[this.LOCALIDX].sel.push(key)
          }
        }
        else {
          this.filters[this.LOCALIDX].sel = [region];
        }
      },
      async searchButtonPressed(){
        await this.getDataFromApi();
      }
    },
    async created() {
      this.keyword = this.$route.query.keyword;

      let option = await this.axios.get('/company/option?type=local')
      this.locals = option.data;

      // await this.getPropositions();
      await this.getDataFromApi();

      if (this.user) {
        let favorites = await this.axios.get('/company/user/favorite?companyId=' + this.user.id + '&taggableType=youtube&inc=1');
        this.favorites = favorites.data.favorites;
      }

      let statistics = await this.axios.get('/youtuber/statistics');
      this.statistics = statistics.data;
      // this.locals.others = {};
      // this._.merge(Object.keys(this.locals).map((key) => {
      //   let obj = {};
      //   obj[this._.invert(this.locals[key])['전체']] = key;
      //   this.locals.others = this._.assign(this.locals.others, obj);
      // }));
      // delete this.locals.others[undefined];
    }
  }
</script>

<style scoped lang="scss">

  .edit-button {
    pointer-events: auto;
  }

  ::v-deep .v-expansion-panel__header {
    padding: 0px;
  }

  ::v-deep .v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none;
  }

  ::v-deep tr.v-data-table__expanded.v-data-table__expanded__row > td {
    border-bottom: none !important;
  }

  ::v-deep tr.v-data-table__expanded.v-data-table__expanded__row > td {
    border-bottom: none !important;
  }

  .row-pointer:hover {
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    bottom: 0px;
    width: 1120px;
    height: 160px;
    background-color: #ffffff
  }
</style>